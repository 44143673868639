import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
        <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/* <h3>LEO 3D<span>.</span></h3> */}
                <img src="assets/img/logo.png" width={"20%"} />
                <p>
                
                 Visakhapatnam<br />
                  <strong>Phone:</strong>+91 8977898989<br />
                  <strong>Email:</strong> bestorganicc@gmail.com<br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <Link to='/'>Home</Link></li>
                  <li><i className="bx bx-chevron-right" /> <Link to="/about">About us</Link></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#services">Products</a></li>
                  {/* <li><i className="bx bx-chevron-right" /> <Link to="/terms">Terms of service</Link></li> */}
                  <li><i className="bx bx-chevron-right" /> <Link to="/contact">Contact</Link></li>
                </ul>
              </div>
              {/* <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Design</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Web Development</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Product Management</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Marketing</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#">Graphic Design</a></li>
                </ul>
              </div> */}
              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>Social Links</h4>
                
                <div className="social-links  pt-3 pt-md-0">
            <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              © Copyright <strong><span></span></strong>. All Rights Reserved
            </div>
            <div className="credits">
             
             
            </div>
          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="#" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div>
        </div> */}
      </footer>

    </div>
  )
}

export default Footer