import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDeepDropdownOpen, setDeepDropdownOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDeepDropdownToggle = () => {
    setDeepDropdownOpen(!isDeepDropdownOpen);
  };

  return (
    <div>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto">
            <img src="assets/img/logo.png" width='100%'  alt="" />
          </Link>
          <nav
            id="navbar"
            className={`order-last order-lg-0 navbar${
              isMobileMenuOpen ? '-mobile' : ''
            }`}
          >
            <ul>
              <li>
                <Link
                  to="/"
                  className="nav-link scrollto"
                  onClick={closeMobileMenu}
                >
               <strong>Home</strong>   
                </Link>
              </li>
              <li>
                <Link
                  to="/About"
                  className="nav-link scrollto"
                  onClick={closeMobileMenu}
                >
                <strong>About</strong>  
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  to="#"
                  className="nav-link scrollto"
                  onClick={handleDropdownToggle}
                >
               <strong style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>Products<i class="bi bi-caret-down-fill"></i></strong>
                </Link>
                <ul className={`dropdown ${isDropdownOpen ? 'dropdown-active' : ''}`}>
                  <li>
                    <Link to="/Turmeric"  onClick={closeMobileMenu}>Turmeric</Link>
                  </li>
                  <li>
                    <Link to="/BlackPepper"  onClick={closeMobileMenu}>Black Pepper</Link>
                  </li>
                  <li>
                    <Link to="/Chilli"  onClick={closeMobileMenu}>Chilli</Link>
                  </li>
                  <li>
                    <Link to="/Rajma"  onClick={closeMobileMenu}>Rajma</Link>
                  </li>
                  <li>
                    <Link to="/Coffee"  onClick={closeMobileMenu}>Coffee</Link>
                  </li>
                  <li>
                    <Link to="/Maize"  onClick={closeMobileMenu}>Maize</Link>
                  </li>
                  <li className="dropdown">
                  <Link to="/Maize" className="dropdown-toggle" onClick={handleDeepDropdownToggle}>
                    Others 
                  </Link>
                  <ul className={`dropdown-menu ${isDeepDropdownOpen ? 'dropdown-active' : ''}`}>
                    <li>
                      <Link to="/" onClick={closeMobileMenu}>Niger Seeds</Link>
                    </li>
                    <li>
                      <Link to="/" onClick={closeMobileMenu}>Cashew</Link>
                    </li>
                    <li>
                      <Link to="/" onClick={closeMobileMenu}>Jowari</Link>
                    </li>
                    <li>
                      <Link to="/" onClick={closeMobileMenu}>Red & Black Grams</Link>
                    </li>

                    

      
                    {/* Add more items for other products as needed */}
                  </ul>
                </li>
                </ul>
              </li>
    
      
              <li>
                <Link
                  to="/Contact"
                  className="nav-link scrollto"
                  onClick={closeMobileMenu}
                >
                 <strong>Contact</strong> 
                </Link>
              </li>
            </ul>
            <i
              className={`bi ${isMobileMenuOpen ? 'bi-x' : 'bi-list'} mobile-nav-toggle`}
              onClick={handleMobileMenuToggle}
            />
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Navbar;
