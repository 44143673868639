import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';

function Chilli() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Breadcrumbs 
        currentpage={"Chilli"} 
        currentTitle={"Chilli"} 
        currentcontent={""} 
        backgroundImage={"assets/img/bg.png"} 
      />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-xs-12 order-2 order-lg-1' style={{ paddingRight: '30px' }}>
              <div className='res-991-pt-0'>
                <h3 className='text-success' style={{ textAlign: 'justify', fontSize:'50px' }}><strong>Chilli</strong></h3>
                <p style={{ textAlign: 'justify' }}>
                  Chilli from Best Organic has a sweet and fruity aroma with an intense fiery heat flavor. It comes with a granulation of 600 microns (30 #) available as whole.
                </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Origin</strong>  </p>
                <p>  Eastern Ghats of India
                </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Common Uses</strong></p>
                <p>  Spices/herbal, culinary use & flavoring & coloring agent in foodstuffs.
                </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Processing</strong></p>
                  <ul>
                    <li>Organic Chilli</li>
                  </ul>
                
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Packing Options</strong><br/>
                </p>
                <div className="d-flex">
                    <div className="text-center mx-2">
                      <img src="assets/img/products/jute-bag-50-kg.png" alt="jute-bag-50-kg.png" className="product-img" width={150} />
                      <p className='mt-2 mb-0'>Jute bag 50kg</p>
                    </div>
                    <div className="text-center mx-2">
                      <img src="assets/img/products/50kg-PP-Bags.png" alt="pp-bag-50-kg.png" className="product-img" width={190} />
                      <p className='mt-2 mb-0'>PP bag 50kg</p>
                    </div>
                  </div>
              </div>
            </div>
            <div className='col-lg-5 col-xs-12 order-1 order-lg-2'>
              <div className='ttm_single_image-wrapper text-right d-flex align-items-end pb-5' >
                <img 
                  className='img-fluid product-img mt-5' 
                  src='assets/img/products/Chilli-1-1.png' 
                  alt='Chilli' 
                  style={{ padding: '5px' }} 
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Chilli;
