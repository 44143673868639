import React from 'react'

function Hero() {
  return (
    <div>
        
           <div class="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div id="header-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
            
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100" src="assets/img/s1.png" alt="Image" />
                    <div class="carousel-caption">
                        
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="assets/img/s2.png" alt="Image" />
                    <div class="carousel-caption">
                        
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="assets/img/s5.png" alt="Image" />
                    <div class="carousel-caption">
                        
                    </div>
                </div>
                <div class="carousel-item">
                    <img class="w-100" src="assets/img/s4.png" alt="Image" />
                    <div class="carousel-caption">
                        
                    </div>
                </div>
               
                </div>
            </div>
           
        </div>
       
    </div>
  )
}

export default Hero