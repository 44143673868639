import {React,useEffect} from 'react';
import Contact from '../components/Contact'

function ContactPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{paddingTop:"70px"}}>
        <Contact />
    </div>
  )
}

export default ContactPage