import React from 'react'

function Whyus() {
  return (
    <div>
         <div class="section-title">
                    <h2>Why Choose Us?</h2>
                    <p></p>
                </div>
        <>
  <meta charSet="utf-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title>Destructuring Object</title>
  {/* Latest compiled and minified CSS */}
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css"
    integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u"
    crossOrigin="anonymous"
  />
  {/* <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia" /> */}
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="col-md-4 col-sm-12 col-xs-12 ">
          <div className="left_right_font left_face_content">
            <h3>Quality and safety    </h3>
            <p style={{textAlign:"justify"}}>
            We adhere to the highest standards of quality and safety in the industry. Each batch of spices undergoes rigorous testing to ensure they are free from contaminants and meet international purity standards.
            </p>
          </div>
          {/* end first right face content*/}
          <div className="left_right_font left_face_content paddding_right_50 p-t-70 responsive_m-b-40">
            <h3>Health and wellbeing            </h3>
            <p style={{textAlign:"justify"}}>
            Our spices are not only flavorful but also packed with health benefits. Rich in antioxidants and essential oils, they contribute to overall wellbeing and enhance the nutritional value of your dishes.
            </p>
          </div>
          {/* end second right face content*/}
        </div>
        {/* end left side col-md-4 contnet*/}
        <div className="col-md-4 col-sm-4 col-xs-12 paddonig">
          <div className="round_circle_box">
            <div className="logo_center">
              <img
                style={{
                  visibility: "visible",
                  animationName: "zoomIn",
                  width: "30%",
                  /* background: 'red', */
                }}
                src="assets/img/logo.png"
                className="wow zoomIn animated"
              />
            </div>
            <div className="top_left">
              <img
                className="wow zoomIn animated"
                src="assets/img/L/1.png"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              />
            </div>
            <div className="top_right">
              <img
                className="wow zoomIn animated"
                src="assets/img/L/cus.png"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              />
            </div>
            <div className="bottom_left">
              <img
                className="wow zoomIn animated"
                src="assets/img/L/6.png"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              />
            </div>
            <div className="bottom_right">
              <img
                className="wow zoomIn animated"
                src="assets/img/L/4.png"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              />
            </div>
            <div className="center_under_logo">
              <img
                className="wow zoomIn animated"
                src="assets/img/L/22.png"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              />
            </div>
          </div>
          {/* end round circle box*/}
        </div>
        {/* end center col-md-4*/}
        <div className="col-md-4 col-sm-12 col-xs-12">
          <div className="left_right_font right_face_content">
            <h3>Best cutomer services</h3>
            <p style={{textAlign:"justify"}}>
            Our customer service team is always ready to assist you with any inquiries or issues. We strive to provide a seamless and satisfying experience, ensuring that your needs are met promptly and efficiently.
      
            </p>
          </div>
          {/* end first left face content*/}
          <div className="left_right_font right_face_content paddding_left_50 p-t-70">
            <h3>Ethical standards</h3>
            <p style={{textAlign:"justify"}}>
            We are dedicated to ethical sourcing and sustainable practices. Our partnerships with local farmers ensure fair trade and support the communities that grow our premium spices.
            </p>
          </div>
          {/* end second left face content*/}
        </div>
        {/* end left face col-md-4*/}
      </div>
      {/* end main col-md-12*/}
      <div className="col-md-12 col-sm-12 col-xs-12">
        <div className="col-md-4 col-sm-12 col-xs-12" />
        <div className="col-md-4 col-sm-12 col-xs-12 text_face_ceneter left_right_font p-t-40">
          <h3>Certifications</h3>
          <p>
          {/* A 3D laser scanner’s quick data capture speed makes it possible to record millions of data points in a matter of seconds. Using quick 3D laser surveying services make it perfect for working on time-sensitive projects where a quick turnaround is essential.  */}
          </p>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12" />
      </div>
    </div>
    {/* end col-md-12 row*/}
  </div>
</>

    </div>
  )
}

export default Whyus