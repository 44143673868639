import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <div>
        {/* <section id="about" class="about" style={{paddingTop:"0px"}}>
                <div class="container" data-aos="fade-up">

                <div class="row no-gutters">
                    <div class="content col-xl-12 d-flex align-items-stretch">
                    <div class="content">
                        <h3>About Us</h3>
                        <p>

                        Welcome to Leo 3d engineering pvt ltd, 
                        your trusted partner for cutting-edge 3D laser scanning services. With a commitment to precision, innovation, and client satisfaction,
                        we specialize in delivering top-notch 3D scanning solutions tailored to meet the unique needs of diverse industries.
                        </p>
                       

                        <p style={{textAlign:"justify"}}> 
                        Our Expertise:
                            At Leo 3d engineering pvt ltd, we bring a wealth of expertise to the world of 3D laser scanning. 
                            Our team of skilled professionals combines technical prowess with a deep understanding of industry requirements, ensuring the delivery of accurate and comprehensive 3D scans for projects of any scale.
                            <br/>
                            <br/>
                            
                            Reality Capture With Onsite 3D Laser Scanning Services
                            At Leo3D enigineering pvt.ltd., we understand the importance of accuracy and precision when it comes to building projects. That’s why we use the latest 3D laser scanning technology to provide you with the most accurate and detailed measurements possible. Our team of experienced professionals ensures that every project is completed with the highest level of quality and attention to detail.
                            <br/>
                            <br/>
                            Our 3D laser scanning services are designed to provide you with a comprehensive view of your building project. We use cutting-edge laser scanners to  capture high-resolution images and detailed point clouds of your project site. This allows us to create accurate 3D models of your building, which can be used for a wide range of applications.
                            <br/>
                            Leo 3D’s skilled laser scan technicians use survey-grade Leica laser scanners, which are at the forefront of the industry, to gather millions of accurate data points for a structure or site.
                            <br/>
                            <br/>
                            With the help of each client, our team of in-house engineers, architects, and CAD designers develops unique deliverables that support any project’s design, planning, and execution phases.

                        </p>
                    </div>
                    </div>
                 
                </div>

                </div>
            </section> */}
            <section id="about" className="about-section">
  <div className="container">
    <div className="row">
      <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
        <div className="inner-column">
          <div className="sec-title">
            <span className="title">About Us</span>
            <h2>Welcome to Best Organic</h2>
          </div>
          <div className="text">
          Welcome to Best Organic, your trusted partner in the world of exquisite Indian spices and pulses. Founded with a passion for bringing the rich flavors of India to kitchens worldwide, we are dedicated to delivering the finest quality spices that elevate culinary experiences and enrich dishes with authentic taste.
          </div>
          <div className="text">
          Quality is at the heart of everything we do. We implement stringent quality control measures at every stage of production, from sourcing to packaging. Our spices undergo rigorous testing to ensure they meet international standards for purity, flavor, and freshness. We use state-of-the-art packaging techniques to preserve the natural oils and flavors, ensuring that our products reach you as fresh as the day they were harvested.
          </div>
          <div className="btn-box">
            <Link to="/About"><a href="#" className="theme-btn btn-style-one">
              Know More
            </a></Link>
          </div>
        </div>
      </div>
      {/* Image Column */}
      <div className="image-column col-lg-6 col-md-12 col-sm-12">
        <div className="inner-column wow fadeInLeft">
      
          <figure className="image-1">
            <a href="#" className="lightbox-image" data-fancybox="images">
              <img
                title=""
                src="assets/img/logo.jpg"
                alt=""
                style={{maxWidth:"100%",height:"auto"}}
              />
            </a>
          </figure>
        </div>
      </div>
    </div>
    <div className="sec-title mt-5 pt-5">
      
      <h2>Our Mission</h2>
    </div>
    <div className="text">
    At Best Organic our mission is to provide premium, authentic, and pure Indian spices to our customers. We are committed to ensuring that every spice we export retains its natural aroma, flavor, and health benefits. We believe in the power of spices to transform dishes and inspire culinary creativity.
    </div>



   
  </div>
</section>

    </div>
  )
}

export default About