import React, { useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';

function BlackPepper() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Breadcrumbs 
        currentpage={"Black Pepper"} 
        currentTitle={"Black Pepper"} 
        currentcontent={""} 
        backgroundImage={"assets/img/bg.png"} 
      />
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-xs-12 order-2 order-lg-1' style={{ paddingRight: '30px' }}>
              <div className='res-991-pt-0'>
                <h3 className='text-success' style={{ textAlign: 'justify', fontSize:'50px' }}><strong>Black Pepper</strong></h3>
                <p style={{ textAlign: 'justify' }}>
                Best Organic Black Pepper or Piper nigrum is the most widely used spice in the world for its pungent, woody aroma and hot, biting taste. Grown in the southwest region of Kerala.
                </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Origin</strong></p>
                <p>  Eastern Ghats of India </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Common Uses</strong> </p>
                <p>  Spices/herbal, culinary use & used as a colorant & flavoring agent. Dye in the textile industry. Used in medicine and cosmetics.
                </p>
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Processing</strong> </p>
                  <ul>
                    <li>Organic Black Pepper MG1</li>
                    <li>Organic Black Pepper Fine Ground</li>
                  </ul>
               
                <p style={{ textAlign: 'justify', fontSize:'25px' }}>
                  <strong>Packing Options</strong><br/>
                </p>
                <div className="d-flex">
                    <div className="text-center mx-2">
                      <img src="assets/img/products/jute-bag-50-kg.png" alt="jute-bag-50-kg.png" className="product-img" width={150} />
                      <p className='mt-2 mb-0'>Jute bag 50kg</p>
                    </div>
                    <div className="text-center mx-2">
                      <img src="assets/img/products/50kg-PP-Bags.png" alt="pp-bag-50-kg.png" className="product-img" width={190} />
                      <p className='mt-2 mb-0'>PP bag 50kg</p>
                    </div>
                  </div>
              </div>
            </div>
            <div className='col-lg-5 col-xs-12 order-1 order-lg-2'>
              <div className='ttm_single_image-wrapper text-right d-flex align-items-end pb-5' >
                <img 
                  className='img-fluid product-img mt-5' 
                  src='assets/img/products/Back-Pepper-1-2-1.png' 
                  alt='Black Pepper' 
                  style={{ padding: '5px' }} 
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlackPepper;
