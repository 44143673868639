import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/Homepage';
import Footer from './components/Footer';

import Contact from './pages/ContactPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import Enq from './components/Enq';
import EnqMail from './components/EnqMail';
import Turmeric from './pages/Turmaric';
import BlackPepper from './pages/BlackPepper';
import Chilli from './pages/Chilli';
import Rajma from './pages/Rajma';
import Coffee from './pages/Coffee';
import Maize from './pages/Maize';


export default function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route path='/Contact' element={<ContactPage /> } />
      <Route path='/About' element={<AboutPage /> } />
      <Route path='/Turmeric' element={<Turmeric />} />  
      <Route path='/BlackPepper' element={<BlackPepper />} /> 
      <Route path='/Chilli' element={<Chilli />} /> 
      <Route path='/Rajma' element={<Rajma />} /> 
      <Route path='/Coffee' element={<Coffee />} /> 
      <Route path='/Maize' element={<Maize />} /> 


      </Routes>
      {/* <Enq /> */}
  
      <Footer />
      
    </Router>
  );
}




