import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <div>
      <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Our Best Organic Products</h2>
            <p></p>
          </div>

          <div className="row">
            <div className="col-md-4 text-center">
              <Link to="/Turmeric">
                <img src='assets/img/products/Turmeric-1.png' width="55%" alt=''  className='product-img'/>
                <p className='mt-5 text-white'><strong>Turmeric</strong> </p>
              </Link>
            </div>
            <div className="col-md-4 text-center">
              <Link to="/BlackPepper">
                <img src='assets/img/products/Back-Pepper-1-2-1.png' width="60%" alt=''  className='product-img'/>
                <p className='text-white'><strong>Black Pepper</strong> </p>
              </Link>
            </div>
            <div className="col-md-4 text-center">
              <Link to="/Chilli">
                <img src='assets/img/products/Chilli-1-1.png' width="60%" alt=''  className='product-img' />
                <p className='text-white'><strong>Chilli</strong> </p>
              </Link>
            </div>
          </div>

          <div className="row mt-5 pt-3">
            <div className="col-md-4 text-center">
              <Link to="/Coffee">
                <img src='assets/img/products/coffee.png' width="60%" alt=''  className='product-img'/>
                <p className='mt-3 text-white'><strong>Coffee</strong> </p>
              </Link>
            </div>
            <div className="col-md-4 text-center">
              <Link to="/Rajma">
                <img src='assets/img/products/rajma.png' width="70%" alt=''  className='product-img'/>
                <p className='mt-3 text-white'><strong>Rajma</strong> </p>
              </Link>
            </div>
            <div className="col-md-4 text-center">
              <Link to="/Maize">
                <img src='assets/img/products/maize1.png' width="50%" alt=''  className='product-img'/>
                <p className='mt-3 text-white'><strong>Maize</strong> </p>
              </Link>
            </div>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Services;
