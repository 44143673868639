import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumbs({ currentcontent, currentpage, currentTitle, backgroundImage }) {
  return (
    <div>
        <section id="breadcrumbs" className="breadcrumbs" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="container">
            <ol style={{color:'white', fontWeight:'bold'}}>
            <li><Link to="/" style={{color:'white', fontWeight:'bold'}}>Home</Link></li>
            <li>{currentpage}</li>
            </ol>
            <h2 style={{ textAlign: 'justify' }}>{currentTitle}</h2>
            <h4>{currentcontent}</h4>
        </div>
        </section>

    </div>
  );
}

export default Breadcrumbs;
