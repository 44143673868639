import React from 'react'

function Featured() {
  return (
    <div>
        <section id="tabs" class="tabs">
                <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Highlights of best organic</h2>
                    <p></p>
                </div>

                <ul class="nav nav-tabs row d-flex">
                    <li class="nav-item col-3">
                    <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#tab-1">
                    <i class="bi bi-award"></i>
                        <h4 class="d-none d-lg-block">Premium quality spices 
                        </h4>
                    </a>
                    </li>
                    <li class="nav-item col-3">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                        <i class="bi bi-hand-thumbs-up"></i>
                        <h4 class="d-none d-lg-block">Sustainble and ethical range
                        </h4>
                    </a>
                    </li>
                    <li class="nav-item col-3">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                    <i class="bi bi-globe-central-south-asia"></i>
                        <h4 class="d-none d-lg-block">Global distribution net work
                        </h4>
                    </a>
                    </li>
                    <li class="nav-item col-3">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                    <i class="bi bi-shield-check"></i>
                        <h4 class="d-none d-lg-block">Heritage and tradition</h4>
                    </a>
                    </li>
                </ul>

                <div class="tab-content">
                    <div class="tab-pane active show" id="tab-1">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="100">
                        <h3>Premium quality spices 
                        </h3>
                        <p class="fst-italic" style={{textAlign:"justify"}}>
                      

                        </p>
                        <ul style={{textAlign:"justify"}}>
                            <li><i class="ri-check-double-line"></i>Sourced directly from India's finest spice farms.</li>
                            <li><i class="ri-check-double-line"></i>Rigorous quality control for purity and freshness.</li>
                            <li><i class="ri-check-double-line"></i>State-of-the-art packaging to retain natural oils and flavors.</li>
                        </ul>
                        {/* <p>
                        With the use of 3D laser scanners, precise point cloud data is gathered. Scanner provide quick scanning, accurate data, time savings, and steady support.
                        </p> */}
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay="200">
                        <img src="assets/img/leo/3.png" width="50%" alt="" class="img-fluid"  className='product-img' />
                        </div>
                    </div>
                    </div>
                    <div class="tab-pane" id="tab-2">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                        <h3>Sustainable and Ethical Practices</h3>
                      
                        <p class="fst-italic" style={{textAlign:"justify"}}>
                        
                        </p>
                        <ul style={{textAlign:"justify"}}>
                            <li><i class="ri-check-double-line"></i> Partnerships with local farmers ensuring fair trade.</li>
                            <li><i class="ri-check-double-line"></i> Investment in sustainable farming methods.</li>
                            <li><i class="ri-check-double-line"></i> Commitment to environmental protection.</li>
                        </ul>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                        <img src="assets/img/leo/4.png" width="50%" alt="" class="img-fluid"  className='product-img' />
                        </div>
                    </div>
                    </div>
                    <div class="tab-pane" id="tab-3">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                        <h3>Global Distribution Network</h3>
                        
                        <p class="fst-italic" style={{textAlign:"justify"}}>
                        

                        </p>
                        <ul style={{textAlign:"justify"}}>
                            <li><i class="ri-check-double-line"></i>Efficient logistics for timely delivery worldwide.</li>
                            <li><i class="ri-check-double-line"></i>  Robust network ensuring spices reach you fresh and flavorful.</li>
                            
                        </ul>
                        
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                        <img src="assets/img/leo/5.png" width="50%" alt="" class="img-fluid"  className='product-img'/>
                        </div>
                    </div>
                    </div>
                    <div class="tab-pane" id="tab-4">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                        <h3>Heritage and Tradition</h3>
                        
                        <p class="fst-italic" style={{textAlign:"justify"}}>
                        



                        </p>
                        <ul style={{textAlign:"justify"}}>
                            <li><i class="ri-check-double-line"></i> Celebrating the rich heritage of Indian spices.</li>
                            <li><i class="ri-check-double-line"></i> Connecting the world to the flavors of India.</li>
                            <li><i class="ri-check-double-line"></i> Preserving traditional spice cultivation practices.</li>
                        </ul>
                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 text-center">
                        <img src="assets/img/leo/6.png" width="50%" alt="" class="img-fluid"  className='product-img' />
                        </div>
                    </div>
                    </div>
                </div>

                </div>
            </section>
    </div>
  )
}

export default Featured