import {React,useEffect} from 'react';
import About from '../components/About'

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div style={{paddingTop:"120px"}}>
        <div class="section-title">
                    <h2>About</h2>
                </div>
        <About />
    </div>
  )
}

export default AboutPage