import React from 'react'

function Benifits() {
  return (
    <div>
                <section id="about" class="about section-bg">
                <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>Our Process</h2>
                    <p></p>
                </div>

                <div class="row no-gutters">
                   
                    <div class="col-xl-12 d-flex align-items-stretch text-center">
                    <div class="icon-boxes d-flex flex-column justify-content-center">
                        <div class="row">
                        <div class="col-md-3 icon-box" data-aos="fade-up" data-aos-delay="100">
                            <img src='assets/img/leo/6.png' alt='' width="50%"  className='product-img'/>
                            <h4>Raw material </h4>
                          
                        </div>
                        <div class="col-md-3 icon-box" data-aos="fade-up" data-aos-delay="200">
                        <img
                            src='assets/img/leo/7.png'
                            alt=''
                            style={{
                            width: '50%',
                            filter: 'invert(50%) brightness(200%)', // Invert color and increase brightness
                            }} 
                             className='product-img'
                        />
                            <h4>Process</h4>
                    
                        </div>
                        <div class="col-md-3 icon-box" data-aos="fade-up" data-aos-delay="300">
                        <img src='assets/img/leo/8.png' alt='' width="50%"  className='product-img'/>
                            <h4>Package</h4>
                           
                        </div>
                        <div class="col-md-3 icon-box" data-aos="fade-up" data-aos-delay="400">
                        <img src='assets/img/leo/9.png' alt='' width="50%"  className='product-img'
                        style={{
                            width: '50%',
                            filter: 'invert(50%) brightness(200%)', // Invert color and increase brightness
                            }}
                             />
                            <h4>Delivery</h4>
                          
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
            </section>
    </div>
  )
}

export default Benifits