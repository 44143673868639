import {React,useEffect} from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Footer from '../components/Footer';
import Featured from '../components/Featured';
import Contact from '../components/Contact';
import Faq from '../components/Faq';
import Benifits from '../components/Benifits';
import Whyus from '../components/Whyus';

function Homepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
 
      <Hero />
      <About />
      <Services />
      <Featured />
      <Benifits />
      <Whyus />
      {/* <Faq /> */}
      <Contact />
  
    </div>
  )
}

export default Homepage